import axios from 'axios';

//Get parameters from url by key
function getQueryVariable(variable) {
    var query = window.location.search.substring(1);
    var vars = query.split("&");
    for(var i=0; i<vars.length; i++) {
        var pair = vars[i].split("=");
        if(pair[0] === variable) {
            return pair[1];
        }
    }
    return (false);
}

// Create instance called instance
//const siteUrl = 'https://www.utf-tour.com/webservices/oop1.0.0/';
const siteUrl = 'https://take5music.in/take5app1/studentApi/index.php/';
const instance = axios.create({
    baseURL: siteUrl,
    headers: {
        'content-type': 'application/json',
        'Client-Service': 'frontend-client',
        'Auth-Key': 'b6a6af7226f48e3a75e9ecff44a212b92141'
    }
});

export default {



    
    registerUserWithMail: (params) => {
        return axios.post(
            siteUrl + 'registerUserMobile',
            {
                'formdata': params
            }
        );
    },


     
    verifyRegisterOTP: (userid,otp) => {
        return axios.post(
            siteUrl + 'verifyRegisterOTP/'+userid+'/'+otp,
            {
                'formdata': ''
            }
        );
    },


    verifyForgotOTP: (userid,otp) => {
        return axios.post(
            siteUrl + 'verifyForgotOTP/'+userid+'/'+otp,
            {
                'formdata': ''
            }
        );
    },
   

    forgotpasswordUpdate: (params,user_id) => {
        return axios.post(
            siteUrl + 'forgotpasswordUpdate/'+user_id,
            {
                'formdata': params
            }
        );
    },

    


    loginWithEmail: (params) => {
        return axios.post(
            siteUrl + 'loginWithEmail',
            {
                'formdata': params
            }
        );
    },

    contactus: (params) => {
        return axios.post(
            siteUrl + 'contactus',
            {
                'formdata': params
            }
        );
    },



    


    classesTimeAvailable: () =>
    instance({
        'method': 'GET',
        'url': 'classesTimeAvailable'
        
    }),




    
    loginWithMobileAndEmail: (params) => {
        return axios.post(
            siteUrl + 'loginWithMobileAndEmail',
            {
                'formdata': params
            }
        );
    },

    submitStartCourseAPI: (params) => {
        return axios.post(
            siteUrl + 'submitStartCourseAPI',
            {
                'formdata': params
            }
        );
    },

    registerUserWithMobile: (params) => {
        return axios.post(
            siteUrl + 'registerUserWithMobile',
            {
                'formdata': params
            }
        );
    },


    UpdateProfile: (params) => {
        return axios.post(
            siteUrl + 'UpdateProfile',
            {
                'formdata': params
            }
        );
    },


    getTeacherList: () =>
    instance({
        'method': 'GET',
        'url': 'getTeacherList'
        
    }),


    getInstrument: () =>
    instance({
        'method': 'GET',
        'url': 'getInstrument'
        
    }),

    getBlogsData: () =>
    instance({
        'method': 'GET',
        'url': 'getBlogsData'
        
    }),



    

    getDemoClass: (user_id) =>
    instance({
        'method': 'GET',
        'url': 'getDemoClass/'+user_id
        
    }),


    getTodayClass: (user_id) =>
    instance({
        'method': 'GET',
        'url': 'getTodayClass/'+user_id
        
    }),





    


    getUpcommingClass: (user_id) =>
    instance({
        'method': 'GET',
        'url': 'getUpcommingClass/'+user_id
        
    }),

    getHistoryClass: (user_id) =>
    instance({
        'method': 'GET',
        'url': 'getHistoryClass/'+user_id
        
    }),

    getClassTime: () =>
    instance({
        'method': 'GET',
        'url': 'getClassTime'
        
    }),

    findClasses: (teacherid) =>
    instance({
        'method': 'GET',
        'url': 'findClasses/'+teacherid
        
    }),


    review_rating: (params,userId,rating) => {
        return axios.post(
            siteUrl + 'review_rating/'+userId+'/'+rating,
            {
                'formdata': params
            }
        );
    },


    getPlanList: () =>
    instance({
        'method': 'GET',
        'url': 'getPlanList'
        
    }),
    


    
    getPaymentHistory: (user_id) =>
    instance({
        'method': 'GET',
        'url': 'getPaymentHistory/'+user_id
        
    }),


    StudentChangePassword: (params,userId) => {
        return axios.post(
            siteUrl + 'StudentChangePassword/'+userId,
            {
                'formdata': params
            }
        );
    },

    getTeacherById: (teacherId) =>
    instance({
        'method': 'GET',
        'url': 'getTeacherById/'+teacherId
        
    }),



    getBookedClassData: (user_id) =>
    instance({
        'method': 'GET',
        'url': 'bookedClasses/'+user_id
        
    }),

    getStudentProfile: (user_id) =>
    instance({
        'method': 'GET',
        'url': 'getStudentProfile/'+user_id
        
    }),


    forgotpasswordWithEmailANDMobile: (params) => {
        return axios.post(
            siteUrl + 'forgotpasswordWithEmailANDMobile',
            {
                'formdata': params
            }
        );
    },

    forgotpasswordWithMobile: (params) => {
        return axios.post(
            siteUrl + 'forgotpasswordWithMobile',
            {
                'formdata': params
            }
        );
    },

    loginUserWithMailMobile: (params) => {
        return axios.post(
            siteUrl + 'loginUserWithMailMobile',
            {
                'formdata': params
            }
        );
    },
    
    
    
}