import React from 'react';
import Header from './Header';
import Footer from './Footer';






const NotFound =(props)=>{
    
  


	return (
			<>
				<Header  />
				
                <div class="breadcrumb-bar">
                    <div class="container-fluid">
                        <div class="row align-items-center">
                            <div class="col-md-12 col-12">
                                <nav aria-label="breadcrumb" class="page-breadcrumb">
                                    <ol class="breadcrumb">
                                        <li class="breadcrumb-item"><a href="index.html">Home</a></li>
                                        <li class="breadcrumb-item active" aria-current="page">Find Classes</li>
                                    </ol>
                                </nav>
                                <h2 class="breadcrumb-title">Find Classes</h2>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="content">
                    <div class="container">
                        <div class="row" >

              
                            
                       
                        
                               
                         </div>  
                          
                    </div>
                </div>

                
				<Footer/>
		</>
	);
	
}
export default NotFound;