import React, { useEffect, useState, useContext } from 'react';
import Header from './Header';
import Footer from './Footer';



const Team =(props)=>{

    
 
    
    
 

    


	return (
			<>
				<Header  />
			
                <div class="take_section take_team_wrapper">
                    <div class="container">
                        <div class="row">
                            <div class="col-lg-12">
                                <div class="take_heading_wrapper text-left">
                                    
                                    <h1>Explore ~ Ideate <br/> Our Team & leads</h1>
                                    <p>for world-class brands to make your products to live for world-class brands to make your<br/> products to live for world-class brands to make your products to</p>
                                    <span class="take_scrolldown">
                                        <img src="assets/images/scroll.png" alt=""/>
                                    </span>
                                </div>
                            </div>
                            <div class="col-lg-5 col-md-12">
                                <div class="take_team_box t1 text-center">
                                    <div class="take_team_img">
                                        <img src="assets/images/t1.png" class="img-fluid" alt=""/>
                                    </div>
                                    <h3>Zetra</h3>
                                    <p>DIGITAL MARKETER</p>
                                </div>
                            </div>
                            <div class="col-lg-7 col-md-12">
                                <div class="row">
                                    <div class="col-lg-6 col-md-12">
                                        <div class="take_team_box t2 text-center">
                                            <div class="take_team_img">
                                                <img src="assets/images/t2.png" class="img-fluid" alt=""/>
                                            </div>
                                            <h3>Bruce Wayne</h3>
                                            <p>WEB DEVELOPER</p>
                                        </div>
                                    </div>
                                    <div class="col-lg-6 col-md-12">
                                        <div class="take_team_box t3 text-center">
                                            <div class="take_team_img">
                                                <img src="assets/images/t3.png" class="img-fluid" alt=""/>
                                            </div>
                                            <h3>Diana Prince</h3>
                                            <p>GRAPHIC DESIGNER</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-7 col-md-12">
                                <div class="row">
                                    <div class="col-lg-6 col-md-12">
                                        <div class="take_team_box t4 text-center">
                                            <div class="take_team_img">
                                                <img src="assets/images/t4.png" class="img-fluid" alt=""/>
                                            </div>
                                            <h3>Jonathan</h3>
                                            <p>UI/UX DESIGNER</p>
                                        </div>
                                    </div>
                                    <div class="col-lg-6 col-md-12">
                                        <div class="take_team_box t5 text-center">
                                            <div class="take_team_img">
                                                <img src="assets/images/t5.png" class="img-fluid" alt=""/>
                                            </div>
                                            <h3>Clark Kent</h3>
                                            <p>ANIMATOR</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-5 col-md-12">
                                <div class="take_team_box t6 text-center">
                                    <div class="take_team_img">
                                        <img src="assets/images/t6.png" class="img-fluid" alt=""/>
                                    </div>
                                    <h3>Barry Allen</h3>
                                    <p>ANDROID DEVELOPER</p>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>

                <div class="take_section take_info_section">
                    <div class="container">
                        <div class="row">
                            <div class="col-lg-12">
                                <div class="take_heading_wrapper">
                                    <h1>Our Philosophy</h1>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Tristique molestie amet, sit amet. Sapien mauris elementum sapien id vitae amet, sollicitudin est id. Bibendum lacus dolor libero risus. Lectus ultricies venenatis cursus eget eleifend egestas dapibus. Scelerisque cras blandit purus amet, vitae dui vel. Cursus leo justo pulvinar aenean pharetra senectus. Suspendisse vulputate amet blandit nibh. Magna id nulla elementum eu. Amet vitae nisl tincidunt bibendum lectus id donec. Eu velit quis faucibus eget porttitor integer commodo. Porta quis proin sodales cras enim. Ultrices aliquet pellentesque id lorem nisi, pretium. Nulla sit sed auctor interdum diam ipsum, praesent mi. Pulvinar urna tincidunt ultrices consectetur ultrices. Nulla ornare quis viverra orci. Vel purus risus, turpis augue a. Arcu nisl tortor, orci, nibh non accumsan enim. Dapibus phasellus proin sem pulvinar purus felis, aliquam. A, ac ut aliquet consectetur nec integer duis. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Tristique molestie amet, sit amet. Sapien mauris elementum sapien id vitae amet, sollicitudin est id. Bibendum lacus dolor libero risus. Lectus ultricies venenatis cursus eget eleifend egestas dapibus. Scelerisque cras blandit purus amet, vitae dui vel. Cursus leo justo pulvinar aenean pharetra senectus. Suspendisse vulputate amet blandit nibh. Magna id nulla elementum eu. Amet vitae nisl tincidunt bibendum lectus id donec. Eu velit quis faucibus eget porttitor integer commodo. Porta quis proin sodales cras enim. Ultrices aliquet pellentesque id lorem nisi, pretium. Nulla sit sed auctor interdum diam ipsum, praesent mi. Pulvinar urna tincidunt ultrices consectetur ultrices. Nulla ornare quis viverra orci. Vel purus risus, turpis augue  </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> 


                
				<Footer/>
		</>
	);
	
}
export default Team;