import React from 'react';

import ProcessTake5 from './ProcessTake5';
import { useForm } from 'react-hook-form';
import Api from '../Api';
import { useAlert } from 'react-alert';
import {Link } from "react-router-dom";
import './main.css';



const Register =(props)=>{
    const alert = useAlert()
    const {
        register,
        handleSubmit,
        formState: { errors },
      } = useForm({
        mode: "onChange" // "onChange /onBlur"
      });

     

      const onSubmit = (data,e) => {
         //console.log(data);   
       // alert(JSON.stringify(data));
       
       Api.registerUserWithMail(JSON.stringify(data)).then(
        (response)=>{
             console.log(response.data.data);
             if(response.data.success){
                e.target.reset(); // reset after form submit   
                localStorage.setItem("registeruserid",response.data.data);
                alert.show(response.data.message, {
                    timeout: 2000, // custom timeout just for this one alert
                    type: 'success',
                    /*onOpen: () => {
                      console.log('hey')
                    }, // callback that will be executed after this alert open
                    */
                    onClose: () => {
                       // return <Redirect to='/verify-OTP' />
                        window.location.href = '/verify-OTP';
                      //console.log('closed')
                    } 
                });

             }else{

               // console.log(response.data.message);
                alert.show(response.data.message, {
                    timeout: 3000, // custom timeout just for this one alert
                    type: 'error'
                    /*onOpen: () => {
                      console.log('hey')
                    }, // callback that will be executed after this alert open
                    onClose: () => {
                      console.log('closed')
                    } */
                });

             }
        }
        ) 


      }
	
		return (
            <>
             
             <div class="auth_main_wrapper Signup">
        <div class="auth_login_wrapper">
            <div class="auth_detail">
                <div class="auth_logo">
                    <a href="javascript:;"><img src="assets/images/logo.png" class="img-fluid" alt=""/></a>
                </div>

                <h1>Create new Account</h1>
                

                <form onSubmit={handleSubmit(onSubmit)}>
                    <div  class="form-group">
                        <label>Student Name</label>
                        <input type="text" {...register('name', { required: true })} name="name" id="" class="form-control" />
                        {errors.name && <p class="error">Name is required.</p>}
                    </div>
                    <div  class="form-group">
                        <label>Email Address</label>
                        <input type="text"  {...register('email', { required: true,pattern: {
            value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
            message: 'Please enter a valid email',
        }, })} name="email" id="" class="form-control" />
                        {errors.email && <p class="error">Please enter a valid email.</p>}
                    </div>
                    <div  class="form-group">
                        <label>Mobile Number</label>
                        <input type="text" name="mobile" {...register('mobile', { required: true ,pattern: {
            value: /^[1-9]\d*(\.\d+)?$/,
            message: 'Please enter a valid email',
        },})} id="" class="form-control" />
                        {errors.mobile && <p class="error">Mobile is required.</p>}
                    </div>

                  



                    <button class="take_btn auth_login_btn" type="submit"> Get Otp <img src="assets/images/arrowright.png" alt=""/></button>      

                </form>
                
                <p class="auth_create_account text-center">Already have an account ? <Link to ="login" class="take_orange">Login</Link>  </p>
            </div>
            <ProcessTake5/>
        </div>
    </div>

        </>
    );
	
}
export default Register;