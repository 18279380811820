import React, { useContext, useEffect,useState } from 'react';
import {
	Link
	
 } from "react-router-dom";

 import { useParams } from 'react-router-dom';
import { authContext } from '../auth/AuthContext'; 
import { useAlert } from 'react-alert';
import { Redirect } from 'react-router';
import {CartContext} from '../auth/CartContext'; 

const Header_inner=(props)=>{
	
	const alert = useAlert();

	const { auth  } = useContext(authContext);
	
	let userId =0;	
	let userImg =0;
	let userName ='';	
	if(auth.data) {
		userId=auth.data.id;
		userImg=auth.data.img;
		userName=auth.data.name;
	}

	const [toggleMenuData,settoggleMenuData] = useState(0); 
	
	


	function logout(){
		
		localStorage.removeItem('plan_className_id');
		alert.show('Log out Successfully', {
			timeout: 2000, // custom timeout just for this one alert
			type: 'success',
			/*onOpen: () => {
			  console.log('hey')
			}, */ // callback that will be executed after this alert open
			onClose: () => {
				localStorage.clear();
				window.location.href = '/';
				//console.log('closed');
			} 
		});

	}

	const logo ={
		width :"155px"
	} 


	function toggleMenu(){
		if(toggleMenuData){
			settoggleMenuData(0);
		}else{
			settoggleMenuData(1);
		}

	}



	return (


        <div class="take_top_header">
            <div class="take_search_wrapper d-none">
                <input type="text" name="" id="" class="form-control" placeholder="Search instruments, teachers"/>
                <span><img src="assets/images/svg/search.svg" alt=""/></span>
            </div>

             <h1 class="take_title">Dashboard</h1>
        </div>

		


		

	);
	
} 
export default Header_inner;