import React from 'react';
import Home from './Home';
import Contact from './Contact';
import About from  './About';
import Cart from './Cart';
import RegisterWithPin from './RegisterWithPin';
import Register from './Register';
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom';
import Login from './Login';
import LoginWithEmail from './LoginWithEmail';

import Teachers from './Teachers';
import ClassessList from './ClassessList';
import TeacherProfile from './TeacherProfile';
import ClassDeatils from './ClassDetails';
import Instruments from './Instruments';
import TodayClasses from './TodayClasses';
import StudentDashboard from './StudentDashboard';
import StudentProfile from './StudentProfile';
import ChangePassword from './ChangePassword';
import FindClasses from './FindClasses';
import plans from './Plans';
import NotFound from './NotFound';
import Term from './Term';
import Privacy from  './Privacy';
import TrasactionHistory from './TrasactionHistory';
import BookedClasses from './BookedClasses';

import ForgotPassword from './ForgotPassword';
import ForgotPasswordMobile from './ForgotPasswordMobile';
import VerifyOTP from './VerifyOTP';
import  Explore from './Explore';
import verifyRegisterOTP from './VerifyOTPForgotPassword';
import VerifyPasswordConfirm from './VerifyPasswordConfirm';
import Team from './Team';
import Faq from './Faq';
import Blogs from './Blogs';
 import RegisterPassword from './RegisterPassword';




let hashHistory = Router.hashHistory;

const Routes = (props) => (
    <Router {...props} history={hashHistory} >
        <Switch>
            <Route exact path="/" component ={Home} />
            <Route path="/contact" component ={Contact} />
            <Route path="/teachers-:instrumentid" component ={Teachers} />
            <Route path="/teacher-profile-:teacherId" component ={TeacherProfile} />
            <Route path="/classess" component ={ClassessList} />
            <Route path="/class-details-:class_id" component ={ClassDeatils} />
            <Route path="/instruments" component ={Instruments} />
            <Route path="/live-classes" component ={TodayClasses} />
            <Route path="/cart" component ={Cart} />
            <Route path="/register" component ={Register} />
            <Route path="/registerwithpin" component ={RegisterWithPin} />
            <Route path="/login" component ={Login} />
            <Route path="/about" component ={About} />
            <Route path="/LoginWithEmail" component ={LoginWithEmail} />

            <Route path="/student-dashboard" component ={StudentDashboard} />
            <Route path="/student-profile" component ={StudentProfile} />
            <Route path="/change-password" component ={ChangePassword} />
            <Route path="/find-classes-:teacherid" component ={FindClasses} />
            <Route path="/plans-:class_id" component ={plans} />

            <Route path="/book-class-:class_id" component ={plans} />

            <Route path="/term-condition" component ={Term} />
            <Route path="/privacy-policy" component ={Privacy} />

            <Route path="/payment-history" component ={TrasactionHistory} />

            <Route path="/booked-classes" component ={BookedClasses} />

            <Route path="/forgot_password" component ={ForgotPassword} />
            <Route path="/forgot_password_mobile" component ={ForgotPasswordMobile} />
            <Route path="/verify-OTP" component ={VerifyOTP} />
            <Route path="/explore" component ={Explore} />

            <Route path="/verify-OTP-forgot" component ={verifyRegisterOTP} />
            <Route path="/verify-password-confirm" component ={VerifyPasswordConfirm} />
            <Route path="/team" component ={Team} />
            <Route path="/faq" component ={Faq} />
            <Route path="/blogs" component ={Blogs} />
            <Route path="/register-password" component ={RegisterPassword} />

            <Route path="*" component ={NotFound} />

            

            
            
        </Switch>
    </Router>
);
export default Routes;
