import React, { useContext, useEffect,useState } from 'react';
import Header from './Header';
import Footer from './Footer';
import StudentSidebar from './StudentSidebar';
import { useForm } from 'react-hook-form';
import Api from '../Api';
import { useAlert } from 'react-alert';
import Helper from '../auth/Helper';


const ChangePassword =(props)=>{


    const [userId,setUserId] = useState(0); 
    
    useEffect(async()=>{
        await Helper.getAtuhData().then((result)=>{
          let userId = result.id;
            setUserId(userId);

        });
   
        
    }, [setUserId]);  


    const alert = useAlert()
    const {
        register,
        handleSubmit,
        formState: { errors },
      } = useForm({
        mode: "onChange" // "onChange /onBlur"
      });

     

      const onSubmit = (data,e) => {
         //console.log(data);   
       // alert(JSON.stringify(data));
       
       Api.StudentChangePassword(JSON.stringify(data),userId).then(
        (response)=>{
             console.log(response.data.data);
             if(response.data.success){
                e.target.reset(); // reset after form submit   
                alert.show(response.data.message, {
                    timeout: 3000, // custom timeout just for this one alert
                    type: 'success',
                    /*onOpen: () => {
                      console.log('hey')
                    }, // callback that will be executed after this alert open
                    */
                    onClose: () => {
                        //return <Redirect to='/LoginWithEmail' />
                      //  window.location.href = '/LoginWithEmail';
                      //console.log('closed')
                    } 
                });

             }else{

               // console.log(response.data.message);
                alert.show(response.data.message, {
                    timeout: 3000, // custom timeout just for this one alert
                    type: 'error'
                    /*onOpen: () => {
                      console.log('hey')
                    }, // callback that will be executed after this alert open
                    onClose: () => {
                      console.log('closed')
                    } */
                });

             }
        }
        ) 


      }
	
		return (
            <>
                <Header />

                <div class="breadcrumb-bar">
                    <div class="container-fluid">
                        <div class="row align-items-center">
                            <div class="col-md-12 col-12">
                                <nav aria-label="breadcrumb" class="page-breadcrumb">
                                    <ol class="breadcrumb">
                                        <li class="breadcrumb-item"><a href="/">Home</a></li>
                                        <li class="breadcrumb-item active" aria-current="page">Change Password</li>
                                    </ol>
                                </nav>
                                <h2 class="breadcrumb-title">Change Password</h2>
                            </div>
                        </div>
                    </div>
                </div>


                <div class="content" >
                    <div class="container-fluid" >
                        <div class="row" >
                            <div class="col-md-5 col-lg-4 col-xl-3 theiaStickySidebar" >
                                <div class="theiaStickySidebar" >
                                    <StudentSidebar/>
                                    
                                </div>
                            </div>
                            <div class="col-md-7 col-lg-8 col-xl-9">
                            <div class="card">
                                <div class="card-body">
                                    <div class="row">
                                        <div class="col-md-12 col-lg-6">
                                            <form onSubmit={handleSubmit(onSubmit)}>
                                                <div class="form-group">
                                                    <label>Old Password</label>
                                                    <input {...register('old_password', { required: true })} type="password" class="form-control"/>
                                                    {errors.old_password && <p class="error">Password is required.</p>}
                                                </div>
                                                <div class="form-group">
                                                    <label>New Password</label>
                                                    <input {...register('new_password', { required: true })} type="password" class="form-control"/>
                                                    {errors.new_password && <p class="error">Password is required.</p>}
                                                </div>
                                                <div class="form-group">
                                                    <label>Confirm Password</label>
                                                    <input {...register('confirm_password', { required: true })} type="password" class="form-control"/>
                                                    {errors.confirm_password && <p class="error">Password is required.</p>}
                                                </div>
                                                <div class="submit-section">
                                                    <button type="submit" class="btn btn-primary submit-btn">Save Changes</button>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>                            
                               
                            </div>
                        </div>
                    </div>
                </div>

                <Footer/>
        </>
    );
	
}
export default ChangePassword;