import React from 'react';



const ProcessTake5 =(props)=>{
   
      
		return (
            <>
             
           
            <div class="auth_img">
                <img src="assets/images/svg/login_shape1.svg" class="shape1 img-fluid" alt=""/>
              
                <img src="assets/images/svg/login_shape2.svg" class="shape2 img-fluid" alt=""/>

                <h1>The process at <br/>Take5Music</h1>
                <p>Easy and the best</p>

                <ul>
                    <li>Login</li>
                    <li>Select Class</li>
                    <li>Select Instrument</li>
                    <li>Class Time</li>
                    <li>Duration</li>
                    <li>Finish</li>
                </ul>
            </div>
       

        </>
    );
	
}
export default ProcessTake5;