import React from  'react';
import Header from './Header';
import Footer from './Footer';

import InstumentSlider from './InstumentSlider';
import TeacherSlider from './TeacherSlider';



import {
	Link
	
 } from "react-router-dom";
import Api from '../Api';



class Home extends React.Component{

	constructor(){
		super();
		this.state = {
			categoryData : [],
			cartData : [],
			productData : [],

		};
	}

	componentDidMount(){
		//localStorage.removeItem("cartLocalData");
		/*Api.getCategortyItems().then(
			(response)=>{
				this.setState({categoryData:response.data.data});
			}
		);

		Api.getAllProduct().then(
			(response)=>{
				this.setState({productData:response.data.data});
			}
		);*/


		
	



		/*let  cartdata =   JSON.parse(localStorage.getItem('cartLocalData'));
		this.setState({	cartData :cartdata });
		cart_data={this.state.cartData}*/
	}	

	render(){
		return (
		<>		

		<div className="take_main_wrapper">
		<Header  />

		<div className="take_banner_wrapper">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-lg-6 col-md-6 col-sm-12">
                        <div className="take_banner_video">
                            <span className=""><img src="assets/images/svg/play.svg" alt=""/></span>
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12">
                        <div className="take_banner_detail">
                            <p className="take_subheading text-uppercase">INDIA’S LEADING MUSIC LEARNING PLATFORM</p>
                            <h1>LEARNING MUSIC <span className="take_orange">MADE EASY</span>  AND FUN ONLY AT TAKE5MUSIC!</h1>
                            <p>Take5Music offers high-calibre learning experiences to every individual with a wide array of music classes. We help the budding musicians to grow their craft proficiently! (Short Description)

                            </p>

                            <div className="take_btn_wrapper">
                                <a href="/register" className="take_btn with_arrow take_enroll_btn">Book a free trial
                                    <span><img className="arrow1" src="assets/images/svg/btn_arrow1.svg" alt=""/><img  className="arrow2" src="assets/images/svg/btn_arrow2.svg" alt=""/></span>
                                </a>
                                <a href="javascript:;" className="take_btn with_border d-none">Book a free trial
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <span className="take_banner_wave"><img src="assets/images/wave_white.png" alt=""/></span>
        </div>

        <div className="take_section take_instrumentcourses_wrapper">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="take_heading_wrapper text-center">
                            <h1>Learn the music that mesmerizes you!</h1>
                            <p>At Take5Music, we make your journey of learning music delightful with the right tools and music mentors on your side. From Guitar and Piano classes to Carnatic vocal and Hindusthani vocal classes, we are a one-stop destination for all forms of music and singing classes.</p>
                            <p>Explore our different instrumental courses and choose what fascinates you!</p>
                        </div>



                        <div className="take_instrument_courses">
                            <span className="take_course c1"><img src="assets/images/c1.png" alt=""/></span>
                            <span className="take_course c2"><img src="assets/images/c2.png" alt=""/></span>
                            <span className="take_course c3"><img src="assets/images/c3.png" alt=""/></span>
                            <span className="take_course c4"><img src="assets/images/c4.png" alt=""/></span>
                            <span className="take_course c5"><img src="assets/images/c5.png" alt=""/></span>
                            <span className="take_course c6"><img src="assets/images/c6.png" alt=""/></span>
                            <span className="take_course c7"><img src="assets/images/c7.png" alt=""/></span>
                            <span className="take_course c8"><img src="assets/images/c8.png" alt=""/></span>
                        </div>

                        <div className="text-center">
                            <a href="login.html" className="take_btn with_border">Login Now
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div className="take_section take_teacher_wrapper">
            <div className="container">
                <div className="row">
                    <div className="col-lg-5 col-md-12">
                        <div className="take_heading_wrapper text-left">
                            <img src="assets/images/wave.png" alt=""/>
                            <h1><span className="take_orange">Let’s get </span> started!</h1>
                            <p>At Take5Music, you get a chance to learn music from India’s top-rated musicians. Whether you’re looking for violin classes or western vocal classes, you get to learn from online music classes conducted by legends from the field.</p>
                        </div>
                    </div>
                    <div className="col-lg-7 col-md-12">
                        <div className="row">
                            <div className="col-lg-4 col-md-6 col-sm-6 col-xs-6">
                                <div className="take_teacher_box text-center">
                                    <div className="take_teacherimg">
                                        <img src="assets/images/teacher1.png" className="img-fluid" alt=""/>
                                        <span><img src="assets/images/star.png" alt=""/> 4/5</span>
                                    </div>
                                    <h3>Laxman</h3>
                                    <p>Drums / Cajon</p>
                                    <a href="javascript:;" className="take_btn">Contact Now</a>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 col-sm-6 col-xs-6">
                                <div className="take_teacher_box text-center">
                                    <div className="take_teacherimg">
                                        <img src="assets/images/teacher1.png" className="img-fluid" alt=""/>
                                        <span><img src="assets/images/star.png" alt=""/> 4/5</span>
                                    </div>
                                    <h3>Laxman</h3>
                                    <p>Drums / Cajon</p>
                                    <a href="javascript:;" className="take_btn">Contact Now</a>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-12 col-sm-12">
                                <div className="take_teacher_box text-center">
                                    <div className="take_teacherimg">
                                        <img src="assets/images/teacher1.png" className="img-fluid" alt=""/>
                                        <span><img src="assets/images/star.png" alt=""/> 4/5</span>
                                    </div>
                                    <h3>Laxman</h3>
                                    <p>Drums / Cajon</p>
                                    <a href="javascript:;" className="take_btn">Contact Now</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div className="take_section take_features_wrapper">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="take_heading_wrapper text-center">
                            <h1 className="take_white">The Take5Music Advantage!</h1>
                        </div>
                        
                        <div className="row">
                            <div className="col-lg-4 col-md-6 col-sm-6 col-xs-6">
                                <div className="take_feature_box">
                                    <img src="assets/images/f1.svg" alt=""/>
                                    <h3>AFFORDABLE PLANS</h3>
                                    <p>Take5Music offers customized music learning plans at the most affordable prices. We make sure that every music lover gets access to our services.</p>
                                    <a href="javascript:;" className="take_orange take_link">Explore</a>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 col-sm-6 col-xs-6">
                                <div className="take_feature_box">
                                    <img src="assets/images/f2.svg" alt=""/>
                                    <h3>LIVE REGULAR CLASSES</h3>
                                    <p>Access to live 1:1 classes to master an existing skill or learn a new instrument. You will remember us from your first chord to the first concert!.</p>
                                    <a href="javascript:;" className="take_orange take_link">Explore</a>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 col-sm-6 col-xs-6">
                                <div className="take_feature_box">
                                    <img src="assets/images/f3.svg" alt=""/>
                                    <h3>INBUILT VIDEO CALL PLATFORM </h3>
                                    <p>With Take5Music, you get a chance to connect with our expert faculty at any point of time. We are just a “video call” away!.</p>
                                    <a href="javascript:;" className="take_orange take_link">Explore</a>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 col-sm-6 col-xs-6">
                                <div className="take_feature_box">
                                    <img src="assets/images/f1.svg" alt=""/>
                                    <h3>MULTIPLE INSTRUMENT COURSES</h3>
                                    <p>We offer various instrument courses - Guitar classes, Piano classes, Drums classes, Violin classes, Flute classes, Tabla classes and others. You can choose one or more and be the musician of your dreams!.</p>
                                    <a href="javascript:;" className="take_orange take_link">Explore</a>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 col-sm-6 col-xs-6">
                                <div className="take_feature_box">
                                    <img src="assets/images/f1.svg" alt=""/>
                                    <h3>ON-CALL SUPPORT</h3>
                                    <p>Have queries about how to start? No worries. Our team is available 24/7 to address all your concerns. So, let’s get the ball rolling!.</p>
                                    <a href="javascript:;" className="take_orange take_link">Explore</a>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 col-sm-6 col-xs-6">
                                <div className="take_feature_box">
                                    <img src="assets/images/f1.svg" alt=""/>
                                    <h3>1st SESSION IS ON US!</h3>
                                    <p>Pay after you trust us! Your first session at Take5Music is free of cost where you get a chance to learn about us and know how we are your perfect fit.</p>
                                    <a href="javascript:;" className="take_orange take_link">Explore</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="take_section take_future_wrapper">
            <div className="container-fluid">
                <div className="row"> 
                    <div className="col-lg-12">
                        <div className="take_future_inner">
                            <div className="take_heading_wrapper text-center">
                                <h1 className="take_white">We are the future of online music learning</h1>
                                <p className="take_white">Music for ALL. Anytime. Anywhere. Any budget.</p>
                            </div>


                            <div className="row">
                                <div className="col-lg-4 col-md-6 col-sm-6 col-xs-6">
                                    <div className="take_future_box text-center">
                                        <h1><span className="take_countto" data-to="100" data-speed="1500">100</span> +</h1>
                                        <h5>100+ Teachers</h5>
                                        <p>Our expert faculty is what makes us stand out from the crowd. Learn from mentors who love teaching.  An unparalleled opportunity to learn from the best teachers.</p>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6 col-sm-6 col-xs-6">
                                    <div className="take_future_box text-center">
                                        <h1><span className="take_countto" data-to="18" data-speed="1500">18</span> +</h1>
                                        <h5>18+ Instruments</h5>
                                        <p>You tell us the name of the instrument and we provide you with a customized learning experience. Learn any instrument at your own pace.</p>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6 col-sm-12">
                                    <div className="take_future_box text-center">
                                        <h1><span className="take_countto" data-to="10" data-speed="1500">10</span>K+</h1>
                                        <h5>10K+ Classes Completed</h5>
                                        <p>We are transforming the way of music learning and this number is just proof.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="take_section take_banners">
            <div className="container">
                <div className="row">
                    <div className="col-lg-6">
                        <div className="take_banner_img">
                            <img src="assets/images/banner_img.jpg" className="img-fluid" alt=""/>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="take_detail">
                            <img src="assets/images/wave.png" alt=""/>
                            <h1>We create a love for music in every learner!</h1>
                            <p>Empower yourself with both the knowledge and perspectives of our expert mentors. Get access to online music learning right away!</p>
                            <a href="javascript:;" className="take_btn">Join Us</a>
                        </div>
                    </div>
                </div>
            </div>
            <span className="take_shape"><img src="assets/images/circle.png" alt=""/></span>
        </div>

        <div className="take_section take_testimonial_wrapper">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="take_heading_wrapper text-center">
                            <h1><span className="take_orange">User</span> TUser Testimonial</h1>
                            <p>Here’s what our learners are saying about us!</p>
                        </div>


                        <div  className="take_testimoal_slider">
                        <div  className="swiper mySwiper2">
                            <div className="swiper-wrapper">
                              <div className="swiper-slide">
                                <div className="take_testimonial_item">
                                    <div className="take_test_img">
                                        <img src="assets/images/testi1.jpg" className="img-fluid" alt=""/>
                                    </div>
                                    <div className="take_test_detail text-center">
                                        <img src="assets/images/quote.png" alt=""/>
                                        <p>Thanks to Take5Music for giving me access to such an amazing learning platform. I opted for violin and drum classes. And believe me the mentors are amazing. They are very patient and encouraging.</p>
                                        <span className="take_orange">- Jermiah </span>
                                    </div>
                                </div>
                              </div>
                              <div className="swiper-slide">
                                <div className="take_testimonial_item">
                                    <div className="take_test_img">
                                        <img src="assets/images/testi1.jpg" className="img-fluid" alt=""/>
                                    </div>
                                    <div className="take_test_detail text-center">
                                        <img src="assets/images/quote.png" alt=""/>
                                        <p>I have been associated with Take5Music for a year now and the experience is superb. Their affordable online piano classes plans have helped me to enhance my piano playing skills. Thanks toTake5Music!.</p>
                                        <span className="take_orange">- Jermiah </span>
                                    </div>
                                </div>
                              </div>
                              <div className="swiper-slide">
                                <div className="take_testimonial_item">
                                    <div className="take_test_img">
                                        <img src="assets/images/testi1.jpg" className="img-fluid" alt=""/>
                                    </div>
                                    <div className="take_test_detail text-center">
                                        <img src="assets/images/quote.png" alt=""/>
                                        <p>Lectus arcu bibendum at varius. Adipiscing diam donec adipiscing tristique.</p>
                                        <span className="take_orange">- Jermiah </span>
                                    </div>
                                </div>
                              </div>
                              <div className="swiper-slide">
                                <div className="take_testimonial_item">
                                    <div className="take_test_img">
                                        <img src="assets/images/testi1.jpg" className="img-fluid" alt=""/>
                                    </div>
                                    <div className="take_test_detail text-center">
                                        <img src="assets/images/quote.png" alt=""/>
                                        <p>Lectus arcu bibendum at varius. Adipiscing diam donec adipiscing tristique.</p>
                                        <span className="take_orange">- Jermiah </span>
                                    </div>
                                </div>
                              </div>
                              <div className="swiper-slide">
                                <div className="take_testimonial_item">
                                    <div className="take_test_img">
                                        <img src="assets/images/testi1.jpg" className="img-fluid" alt=""/>
                                    </div>
                                    <div className="take_test_detail text-center">
                                        <img src="assets/images/quote.png" alt=""/>
                                        <p>Lectus arcu bibendum at varius. Adipiscing diam donec adipiscing tristique.</p>
                                        <span className="take_orange">- Jermiah </span>
                                    </div>
                                </div>
                              </div>
                              <div className="swiper-slide">
                                <div className="take_testimonial_item">
                                    <div className="take_test_img">
                                        <img src="assets/images/testi1.jpg" className="img-fluid" alt=""/>
                                    </div>
                                    <div className="take_test_detail text-center">
                                        <img src="assets/images/quote.png" alt=""/>
                                        <p>Lectus arcu bibendum at varius. Adipiscing diam donec adipiscing tristique.</p>
                                        <span className="take_orange">- Jermiah </span>
                                    </div>
                                </div>
                              </div>
                              <div className="swiper-slide">
                                <div className="take_testimonial_item">
                                    <div className="take_test_img">
                                        <img src="assets/images/testi1.jpg" className="img-fluid" alt=""/>
                                    </div>
                                    <div className="take_test_detail text-center">
                                        <img src="assets/images/quote.png" alt=""/>
                                        <p>Lectus arcu bibendum at varius. Adipiscing diam donec adipiscing tristique.</p>
                                        <span className="take_orange">- Jermiah </span>
                                    </div>
                                </div>
                              </div>
                            </div>
                            
                          </div>
                          <div thumbsSlider="" className="swiper mySwiper">
                            <div className="swiper-wrapper">
                              <div className="swiper-slide t1">
                                <img src="assets/images/testi1.jpg" className="img-fluid" alt=""/>
                              </div>
                              <div className="swiper-slide t2">
                                <img src="assets/images/testi1.jpg" className="img-fluid" alt=""/>
                              </div>
                              <div className="swiper-slide t3">
                                <img src="assets/images/testi1.jpg" className="img-fluid" alt=""/>
                              </div>
                              <div className="swiper-slide t4">
                                <img src="assets/images/testi1.jpg" className="img-fluid" alt=""/>
                              </div>
                              <div className="swiper-slide t5">
                                <img src="assets/images/testi1.jpg" className="img-fluid" alt=""/>
                              </div>
                              <div className="swiper-slide t6">
                                <img src="assets/images/testi1.jpg" className="img-fluid" alt=""/>
                              </div>
                              <div className="swiper-slide t7">
                                <img src="assets/images/testi1.jpg" className="img-fluid" alt=""/>
                              </div>
                            </div>
                          </div>
                          <div className="swiper-button-next swiper-nav"><img src="assets/images/arrow_right.png" alt=""/></div>
                            <div className="swiper-button-prev swiper-nav"><img src="assets/images/arrow_left.png" alt=""/></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        


        <div className="take_section take_certificate_wrapper">
            <div className="container">
                <div className="row">
                    <div className="col-lg-5 col-md-12">
                        <div className="take_heading_wrapper">
                            <h1>Prepare confidently for <span className="take_orange">global certification courses</span></h1>
                            <p>We prepare you for various certification courses like Trinity, ABRSM and Rockschool</p>
                        </div>
                    </div>
                    <div className="col-lg-7 col-md-12">
                        <div className="take_certi_images text-center">
                            <img src="assets/images/certi1.png" className="img-fluid take_c1" alt=""/>
                            <img src="assets/images/certi2.png" alt="" className="img-fluid take_c2"/>
                            <img src="assets/images/certi5.png" alt="" className="img-fluid take_c3"/>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div className="take_section take_banners">
            <div className="container">
                <div className="row">
                    <div className="col-lg-6">
                        <div className="take_banner_img">
                            <img src="assets/images/banner_img.jpg" className="img-fluid" alt=""/>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="take_detail">
                            <img src="assets/images/wave.png" alt=""/>
                            <h1>Are you a music teacher? Join the Take5Music Community!</h1>
                            <p>Be a part of an exclusive music community and connect with your potential students from all over India today!.</p>
                            <a href="javascript:;" className="take_btn">Join Us</a>
                        </div>
                    </div>
                </div>
            </div>
            <span className="take_shape_left"><img src="assets/images/circle1.png" alt=""/></span>
        </div>

        <div className="take_section take_blog_wrapper">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="take_heading_wrapper text-center">
                            
                            <h1>Our Blogs</h1>
                            <p>Our Psychological Model is accurate, verified and tested over thousands of individuals.<br/>The most meaningful social media platform, ever.</p>
                        </div>
                        <div className="row">
                            <div className="col-lg-4 col-md-6">
                                <div className="take_blog">
                                    <div className="take_blog_img">
                                        <img src="assets/images/blog1.png"  className="img-fluid" alt=""/>
                                        <span><img src="assets/images/svg/reply.svg" alt=""/></span>
                                    </div>
                                    <p>How to make the perfect morning coffee, according to the Science</p>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6">
                                <div className="take_blog">
                                    <div className="take_blog_img">
                                        <img src="assets/images/blog2.png"  className="img-fluid" alt=""/>
                                        <span><img src="assets/images/svg/reply.svg" alt=""/></span>
                                    </div>
                                    <p>How to make the perfect morning coffee, according to the Science</p>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6">
                                <div className="take_blog">
                                    <div className="take_blog_img">
                                        <img src="assets/images/blog3.png"  className="img-fluid" alt=""/>
                                        <span><img src="assets/images/svg/reply.svg" alt=""/></span>
                                    </div>
                                    <p>How to make the perfect morning coffee, according to the Science</p>
                                </div>
                            </div>
                        </div>
                        <div className="text-center">
                            <a href="javascript:;" className="take_btn with_border">More Blogs</a>
                        </div>
                    </div>
                    
                </div>
            </div>
        </div>

	
	  <Footer/>
	  </div>
	  </>
		);
	}
	
}

export default Home;